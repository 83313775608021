import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_ALL_PRODUCTS_FROM_HARVEST,
  GET_PRODUCTS_BY_IDS,
} from '../queries';

const handleShuffling = (items: any) =>
  items
    .map((value: any) => ({ value, sort: Math.random() }))
    .sort((a: any, b: any) => a.sort - b.sort)
    .map(({ value }: any) => value)
// ?.filter((product: any) => product?.Name);

export const useSimilarProducts = (skip?: boolean) => {
  const { productId, storeId, harvestId } = useParams();
  const [shuffledProducts, setShuffledproducts] = React.useState({
    shuffledSimilar: [],
    shuffledRecommended: [],
  });
  const [productsToFetch, setProductsToFetch] = React.useState([])

  const { data: allProducts, loading: allProductsLoading } = useQuery(
    GET_ALL_PRODUCTS_FROM_HARVEST,
    {
      skip: !storeId || !harvestId || skip,
      variables: {
        fullpath: `/ePog Product Data/Products/${storeId}/${harvestId}`,
      },
    }
  );

  const { data: similiarData, loading: similarLoading } = useQuery(GET_PRODUCTS_BY_IDS,
    {
      variables: {
        ids: productsToFetch?.toString(), //list of products ids
      },
      skip: allProductsLoading || productsToFetch.length < 1 || skip,
    }
  );

  React.useEffect(() => {
    if (!allProductsLoading && allProducts) {
      const products = allProducts?.getHarvest.children
        .filter((child: { id: string; }) => child.id !== productId?.split('__')[0])
        .map((child: { id: string; }) => child.id);

      const randomizedProducts = handleShuffling(products);

      setProductsToFetch(randomizedProducts.slice(0, 20));
    }
  }, [allProducts, allProductsLoading]);

  React.useEffect(() => {
    if (!similarLoading && similiarData) {
      const products = similiarData?.getEPogListing.edges.map((edge: { node: any; }) => edge.node);

      const shuffledSimilar = products.slice(0, 10);
      const shuffledRecommended = products.slice(10, 20);

      setShuffledproducts({ shuffledSimilar, shuffledRecommended });
    }
  }, [similarLoading, similiarData]);

  return {
    similarLoading,
    shuffledProducts,
  };
};
