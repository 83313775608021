import React from 'react';

import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';
import { LIST_ALL_TERMS } from '../../queries';
import { contentSquereCustomTag, artificialPageview } from '../../helpers'
import { useSimilarProducts } from '../../hooks/useSimilarProducts';

export const useMainRoute = () => {
  const { storeId, harvestId } = useParams();
  const {pathname} = useLocation();
  const { data, loading } = useQuery(LIST_ALL_TERMS);
  const { shuffledProducts, similarLoading } = useSimilarProducts();

  const [categories, setCategories] = React.useState();

  React.useEffect(() => {
    if (!loading) {
      const edges = data?.getEPogTermListing?.edges;

      const categories = edges
        ?.filter(
          (edge: any) =>
            edge?.node?.fullpath?.includes(storeId) &&
            edge?.node?.fullpath?.includes(harvestId) &&
            edge?.node?.fullpath?.includes('Category')
        )
        .map((value: any) => ({ value, sort: Math.random() }))
        .sort((a: any, b: any) => a.sort - b.sort)
        .map(({ value }: any) => value);
      setCategories(categories);
    }
  }, [loading, data, harvestId, storeId]);

  React.useEffect(() => {
      const timer = (!loading) ? setTimeout(() => window.scrollTo(0, 0), 5) : null;
      return () => {timer && clearTimeout(timer)};
  }, [pathname]);

  React.useEffect(() => {
    contentSquereCustomTag(1,'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2,'pageType','HomePage','')
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
  }, [])

  return {
    loading,
    similarLoading,
    shuffledProducts,
    categories,
  };
};
