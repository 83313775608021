import React from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { DeliveryDate } from '../../components/DeliveryDate';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useCartHook } from './useCartHook';
import { SimilarProducts } from '../../components/SimilarProducts';
import { CartRouteStyled, CustomLabelStyledDiv, HideDetails } from './styles';
import { ReactSVG } from 'react-svg';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useConfiguration } from '../../hooks/useConfiguration';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { CheckoutButtonPositionEnum, IpdpField } from '../../types/config';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries'
import { PdpFieldName } from '../../types/config';

const checkRecommendedProductsEnabled = (PDPFields?:IpdpField) => {
  if (!PDPFields) return false;
  if (Array.isArray(PDPFields)){
    return (PDPFields.some(PDPField => (PDPField.Name === PdpFieldName.Recommended || PDPField.Name === PdpFieldName.ProductYouMakeLike)));
  } else{
    return (PDPFields.Name === PdpFieldName.Recommended || PDPFields.Name === PdpFieldName.ProductYouMakeLike);
  }
}

export const CartRoute = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [recommendedProductsEnabled, setRecommendedProductsEnabled] = React.useState(false);

  const { handleCart, checkout, totalCartValue, totalItemsInCart, checkoutMessage } =
    useCartHook();

  const { storeId = '', harvestId = '' } = useParams();

  // const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { LanguageVersion, CheckoutButtonPosition, Currency, eRetailer, PDPFields } = useConfiguration({
    storeId,
    harvestId,
  });

  React.useEffect(() => {
    setRecommendedProductsEnabled(checkRecommendedProductsEnabled(PDPFields));
  }, [PDPFields]);

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const {
    value: { cart },
  } = useAppContext();

  const navigate = useNavigate();

  let href = window.location.pathname;

  /*if (checkoutMessage != "" && href.indexOf('checkout') == -1) {
    navigate(href + "/checkout")
  }*/

  const normalizeNameForUrl = (name: string|null) => {
    if (!name) return '';
    return name
        .normalize('NFD')
        .replaceAll(' ', '_')
        .replace(/[^a-zA-Z0-9_ ]/g, '');
  }
//product.id + '__' + product.Brand + '__' + normalizeNameForUrl(product.Name) + '_' + price + promoCode + '_cartadd' + product.amount
  const navigateToProduct = (id: string, name: string, brand: string, price: number, promo: string, amount: number) =>
    navigate(`/${storeId}/${harvestId}/ips/${id}__${brand}__${name}_${price}${promo}_cartadd${amount}`);


  const currency = Currency ? Currency : '$';

  if (totalItemsInCart && totalItemsInCart > 0) {
    return (
      <CartRouteStyled>
        <p>
        {translation?.getLocalization.Cart} <span> ({totalItemsInCart} {translation?.getLocalization.Items})</span>
        </p>
        
        <div className="cart-grid">
          <div className="cart-list">
            <div>
          <DeliveryDate
                    text={translation?.getLocalization.ShippingArrives}
                    className="header"
              />
            {cart.map((product: IProduct, index: number) => {
              const productInCart = cart.find(
                (productInCart: IProduct) => product.id === productInCart?.id
              ) as IProduct;
              const isAmount = productInCart.amount === 0 ? false : true;
              let totalAmount = ''
              const price = product.Price != 0
                ? product.Price
                : product.RegularPrice;
              if (product.PromoAmount && productInCart.amount >= product.PromoAmount) { 
                totalAmount = ((Math.floor(productInCart.amount / product.PromoAmount) * product.PromoCount * price) + ((productInCart.amount % product.PromoAmount) * price)).toFixed(2);
              } else {
                totalAmount = (productInCart.amount * price).toFixed(2);
              }
              const promo = product.URLPromoCode ? '__promoCode-' + product.URLPromoCode : '__promoCode-';

              return (
                <div key={index}>
                  <div className="inner-content">
                    <p>{translation?.getLocalization.SoldBy} {eRetailer}</p>
                    <div className="product-details">
                      <div className="name">
                        {product.Gallery && product.Gallery[0] && (
                          <img
                            src={`${imgSrcByEnv()}${
                              product.Gallery[0]?.image?.fullpath
                            }`}
                            alt="product logo"
                            onClick={() => navigateToProduct(product.id, normalizeNameForUrl(product.Name), product.Brand, price, promo, productInCart.amount)}
                          />
//  const navigateToProduct = (id: string, name: string, brand: string, price: string, promo: string, amount: string)
                        )}
                        <div>
                          <p onClick={() => navigateToProduct(product.id, normalizeNameForUrl(product.Name), product.Brand, price, promo, productInCart.amount)}>
                            {product.Name}
                          </p>
                          {/* <p>Shipping only</p> */}
                        </div>
                      </div>
                      <span className="total-amount">
                        {currency}
                        {totalAmount}
                      </span>
                    </div>
                    {product.LabelText && 
                        <CustomLabelStyledDiv LabelColor={product.LabelColorCode} 
                        LabelTextColor={product.LabelTextColor} >
                          {product.LabelText}
                        </CustomLabelStyledDiv>
                    }
                    <div className="footer">
                      {/* LabelColor={product.LabelColorCode} LabelPosition={product.LabelPosition} */}
                      <Button onClick={() => handleCart('removeAll', product)}>
                      {translation?.getLocalization.Remove}
                      </Button>
                      {/* <Button>{translation?.getLocalization.SaveForLater}</Button> */}
                      <div>
                        <IconButton
                          size="small"
                          onClick={() => handleCart('remove', product)}
                        >
                          <ReactSVG src="/files/generic/img/minus.svg" />
                        </IconButton>
                        <span>{isAmount ? productInCart.amount : 0}</span>

                        <IconButton
                          size="small"
                          onClick={() => handleCart('add', product)}
                        >
                          <ReactSVG src="/files/generic/img/plus.svg" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          </div>
          <div className="cart-total">
            {CheckoutButtonPosition === CheckoutButtonPositionEnum.Top && (
              <div className="checkout-button-top">
                <Button onClick={() => checkout('checkout', cart)} >{translation?.getLocalization.ContinueToCheckout}</Button>
                <hr />
              </div>
            )}
            <div className="total-line">
              <span>
              {translation?.getLocalization.Subtotal} <span>({totalItemsInCart} {translation?.getLocalization.Items})</span>
              </span>
              <span>
                {currency}
                {totalCartValue}
              </span>
            </div>
            <div className="total-line">
              <span className="taxes">{translation?.getLocalization.Taxes}</span>
              <span className="calculated">{translation?.getLocalization.CalculatedAtCheckout}</span>
            </div>
            <hr />
            <div className="total-line">
              <span>{translation?.getLocalization.EstimatedTotal}</span>
              <span>
                {currency}
                {totalCartValue}
              </span>
            </div>
            <div className="total-footer">{translation?.getLocalization.AdditionalInfoHere}</div>
            {CheckoutButtonPosition === CheckoutButtonPositionEnum.Bottom && (
              <div className="checkout-button-bottom">
                <hr />
                <Button>{translation?.getLocalization.ContinueToCheckout}</Button>
              </div>
            )}
          </div>
        </div>

        {/* { recommendedProductsEnabled && 
        <div className="similar-products">
          <p>{translation?.getLocalization.CustomersAlsoConsidered}</p>
          <SimilarProducts products={shuffledProducts} />
        </div>
        } */}
      </CartRouteStyled>
    );
  } else {
    if (checkoutMessage != "") {
      return (
        <CartRouteStyled>
          <div className="empty">{translation?.getLocalization.CheckoutMessage}</div>
        </CartRouteStyled>
      );
    } else {
      return (
        <CartRouteStyled>
          <div className="empty">{translation?.getLocalization.CartIsEmpty}</div>
        </CartRouteStyled>
      );
    }
  }
};
