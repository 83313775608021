import React from 'react';

import { Code } from 'react-content-loader';

import { IProduct } from '../../types';
import { PdpCard } from '../../components/PdpCard';

import { usePdpRoute } from './usePdpRoute';
import { PdpRouteStyled } from './styles';
import { PdpRating } from '../../components/PdpRating';
import { PdpReviews } from '../../components/PdpReviews';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IpdpField, PdpFieldName } from '../../types/config';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';
import { PdpGallerySlider } from '../../components/PdpGallerySlider';
import ProductDescription from '../../components/ProductDescription';

export const PdpRoute = () => {
  const {
    loading,
    categoryLoading,
    categoryData,
    data,
    categoryId,
  } = usePdpRoute();

  const { storeId = '', harvestId = '' } = useParams();

  const { PDPFields, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  if (loading || categoryLoading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    const categoryEdges = categoryData?.getEPogTermListing?.edges;
    const fullpath =
      categoryEdges && categoryEdges[0] && categoryEdges[0]?.node?.fullpath;
    const breadcrumbs = fullpath?.split(`/${harvestId}`)[1];

    const product = data.getEPog as IProduct;

    return (
      <PdpRouteStyled>
        <div className="container">
          {categoryId && <div>{breadcrumbs}</div>}
          <div className="details">
            {/* <PdpGallery product={product} /> */}
            <PdpGallerySlider product={product}/>
            <PdpCard product={product} />
          </div>
        </div>
        <h2>{translation?.getLocalization.AboutThisItem}</h2>
        <hr />
        {PDPFields &&
          Object.values(PDPFields).map((field: IpdpField, index: number) => {
            if (field.Name === PdpFieldName.Description) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                      <ProductDescription content={product?.Description} header={translation?.getLocalization.ProductDetails}/>
                  </div>
                  <hr />
                </React.Fragment>
              );
            }

            // if (field.Name === PdpFieldName.ProductYouMakeLike) {
            //   return (
            //     <React.Fragment key={index}>
            //       <div className="container">
            //         <h2>
            //         {translation?.getLocalization.CustomersAlsoConsidered}
            //         </h2>

            //         <SimilarProducts
            //           className="productYouMakeLike"
            //           products={shuffledProducts?.shuffledSimilar}
            //         />
            //       </div>
            //       <hr />
            //     </React.Fragment>
            //   );
            // }

            // if (field.Name === PdpFieldName.Recommended) {
            //   return (
            //     <React.Fragment key={index}>
            //       <div className="container">
            //         <h3>{translation?.getLocalization.RecommendedForYou}</h3>
            //         <p>{translation?.getLocalization.PairWellTogether}</p>
            //         <SimilarProducts
            //           className="recommended"
            //           products={shuffledProducts?.shuffledRecommended}
            //         />
            //       </div>
            //       <hr />
            //     </React.Fragment>
            //   );
            // }

            if (field.Name === PdpFieldName.Reviews) {
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="reviews">
                      <h3>{translation?.getLocalization.CustomerReviewsRatings}</h3>

                      <PdpRating product={product} />
                      <h3>{translation?.getLocalization.AllReviews}</h3>

                      <PdpReviews product={product} />
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              );
            }
            return '';
          })}
      </PdpRouteStyled>
    );
  }
};
